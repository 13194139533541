/* eslint-disable no-unused-vars */
import commonPkg from '@point/classes';
import { Theme } from '@point/utility-classes';
import DateRange from '../../../types/dateRange';
import { LayoutSection } from '../../../types/layout';

const { roles } = commonPkg;
export type AuthUser = typeof roles.AuthUser;

export interface AdPerformance {
  PRDSC: string;
  PRPPID: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ByCreative: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

declare global {
  interface Window {
    noAdBlocker: boolean;
  }
}

export interface AdvertiserPolygons {
  PRDSC: string;
  PRPPID: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ByCreative: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface AdvertiserDataApi {
  name: string;
  id: string;
  agency_id: string;
  AgencyPartner?: string;
  agency_name: string;
  created_at: string;
  stations: string[];
  status: string;
  last_campaign_date: string;
  url: string;
  subagencies: string[];
  active_campaigns: string;
}

export enum FilterTypes {
  AdStations = 'AdStations',
  AgencyPartner = 'AgencyPartner',
  SubAgency = 'SubAgency',
}

export enum FacetsDisplayName {
  Stations = 'Stations',
  Partner = 'Partner',
  SubAgency = 'SubAgency',
}

export interface AdvertiserFilter {
  DisplayName: FacetsDisplayName | null;
  Elements: FilterElement[] | [];
  Key: FilterTypes | null;
}

export interface FilterElement {
  Value?: string;
  Value_Agency_Id?: string;
  Value_Agency_Name?: string;
  Count: string;
}

export interface SortOptions {
  by: string;
  is_descending: boolean;
}

export interface AdvertisersData {
  offset: number;
  limit: number;
  total: number;
  sort: SortOptions;
  advertisers: AdvertiserDataApi[];
  facets: AdvertiserFilter[];
}

export interface Advertiser {
  DataSourceCode?: string;
  PropertyId: string;
  Name?: string;
  Station?: string[];
  Creative?: string[];
  Agency?: string;
}

export interface RecentAdvertiser {
  PropertyId: string;
  Name: string;
  Agency: string;
}
export interface Selection {
  daterange: string | null;
  campaigndaterange: string | null;
  advertiserName: string | null;
  advertiserId: string | null;
  SFLT: string[] | null;
  creative: string[] | null;
  startdate: string | null;
  enddate: string | null;
  campaignstartdate: string | null;
  campaignenddate: string | null;
  layout: string | null;
  tab: string | null;
  campaigns: string | null;
  dmas: string | null;
  types: string | null;
  viewCampaigns: string | null;
}

export interface LoadedDateRanges {
  loaded: boolean;
  list: DateRange[];
}

export interface LoadedAdvertisers {
  loaded: boolean;
  list: Advertiser[];
}
export interface LoadedAllAdvertisers {
  loaded: boolean;
  list: Advertiser[];
}
export interface Campaigns {
  defaultdaterange: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  campaignlist: any[];
}
export interface CampaignDetails {
  AnalyticsType: string;
  CampaignId: string;
  CampaignName: string;
  Clicks: number;
  EndDate: string;
  FeedSource: string | string[];
  DataSource: string | string[];
  Frequency: string;
  HideSpend: boolean;
  ImpressionGoal: string;
  Impressions: number;
  Pacing: string;
  PacingImpressions: string;
  Reach: string;
  UniqueClicks: number;
  StartDate: string;
  Tactics: Array<string>;
}

export interface TacticProduct {
  DataSourceCode: string;
  PropertyId: string;
  pstate: string;
  DateRange: string;
  StartDate: string;
  EndDate: string;
  CampTypes: Array<string>;
}

export interface StateType {
  user: AuthUser | null;
  error: object | null;
  waitingForAuth: boolean;
  currentNavTab: string | null;
  currentSection: LayoutSection | null;
  // filters: Filters;
  selection: Selection;
  loadingHasRight: boolean;
  loadingAllAdvertisers: boolean;
  loadingSummaryPerformance: boolean;
  loadingTacticSummaryPerformance: boolean;
  loadingAdPerformance: boolean;
  loadingProducts: boolean;
  loadingCampaignAdPerformance: boolean;
  loadingAllTimeAdPerformance: boolean;
  networkActivityCounter: number;
  loadingAdvertiserPolygons: boolean;
  loadingAdvertiserGeocodes: boolean;
  loadingDMAPolygons: boolean;
  loadingZipPolygons: boolean;
  exportingData: object | null;
  loadingExportContent: boolean;
  loadingShareContent: boolean;
  sharedDashboard: boolean;
  sharedSelection: object | null;
  sharedDateRanges: object | null;
  loadingLayouts: boolean;
  currentDashboard: {
    products: TacticProduct;
    validatedProductSections: Array<LayoutSection>;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  summaryPerformance: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tacticSummaryPerformance: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  adPerformance: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  campaignAdPerformance: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allTimeAdPerformance: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allTimeCampaigns: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  advertiserPolygons: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dmaPolygons: any;
  zipPolygons: {
    loaded: boolean;
    cacheKey?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    polygons?: any;
  };
  dateRanges: LoadedDateRanges;
  advertisers: LoadedAdvertisers;
  allAdvertisers: LoadedAllAdvertisers;
  adBlockDetected: boolean;
  defaultPeriod: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debugFlags: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layouts: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dynamicLayout: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dynamicLayoutSource: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultLayout: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dashboard: any;
  theme: Theme;
  editTheme: Theme;
  campaigns: Campaigns;
  staticLastModifiedDate: string;
  exportState: object | null;
  processing: [];
  orderListState: object | null;
  summaryDateRanges: object | null;
}

export interface SetCampaignFriendlyName {
  advertiserId: string;
  campaignId: string;
  friendlyName: string;
  campaignSource?: string;
}
export interface AgencyPartner {
  AgencyPartner?: string | null | undefined;
}
